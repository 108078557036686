import React,{useEffect, useState, useRef, memo} from 'react'
//import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";
import YouTube from "react-youtube";
import { YouTubePlaylist } from "../../components/YtPlaylist/YouTubePlaylist"
import YtPlayer from "../../components/YtPlayer"
import getPlaylistData from "../../components/YtPlaylist/getPlaylistData";
import {
  YouTubePlaylistPropsType,
  PlaylistData,
} from "../../components/YtPlaylist/YouTubePlaylist.types";
import Layout from "../../components/layout"
//import Lenis from '@studio-freight/lenis';
//import Seo from "../../components/seo"
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { InertiaPlugin } from "gsap/InertiaPlugin"

import "../../scss/style.scss"

gsap.registerPlugin(ScrollToPlugin);
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(InertiaPlugin);

}




const AboutPage = React.memo((props) => {

  const videoRef = useRef(null);

  const [jsonData, setJsonData] = useState(null);

const [isPlayer, setIsPlayer] = useState(null);
const [playerAction, setPlayerAction] = useState('play');
const [isHover, setIsHover] = useState(-1);
const [playerHeight, setPlayerHeight] = useState(0);
const [playerWidth, setPlayerWidth] = useState(0);

const opts = {
  width: playerWidth,
  height: playerHeight,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    muted: 1,
    controls: 0,
    enablejsapi: 1,
    fs: 0,
    start:1,
    end: 10,
    loop: 1,
    rel: 0,
    modestbranding: 1,
    showinfo: 0,
    playlist: jsonData === null ? '' : jsonData[0].resourceId.videoId,
    hd:1,
    origin: 'dev.frankariza.com'
  },
};

function updateDimensions() {
  var _gatsbyDiv = document.getElementById("___gatsby");
  var _w = _gatsbyDiv.offsetWidth;
  var _h = _w * 0.56;
  setPlayerWidth(_w);
  setPlayerHeight(_h);
  };

useEffect(() => {

  //set the top player fullwidth with 16:9 proportion
  updateDimensions()

},[]);

//Resize events
useEffect(() => {
window.addEventListener('resize', updateDimensions);

return ()=> {
  window.removeEventListener('resize', updateDimensions);
};
},[]);

useEffect(()=> {
  let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.8
};

let callback = (entries, observer) => {
  entries.forEach(entry => {

          if (entry.isIntersecting && isPlayer) {
              isPlayer.playVideo();
          }
          else if(isPlayer){
              isPlayer.pauseVideo();;
          }


  });
}

let observer = new IntersectionObserver(callback, options);
if(isPlayer){
const _video = document.querySelector("iframe");
  observer.observe(_video);
  }
},[isPlayer]);



  useEffect(() => {
    const _data = getPlaylistData().then((newData) => {
      /*const newUrls = newData.map((data) => {
        return `https://www.youtube.com/watch?v=${data.resourceId.videoId}`;
      });*/
      //setUrls([...urls, ...newUrls]);
      setJsonData([...newData]);
    })
    .catch((e) =>
      console.error(`Error getting next page playlist data: ${e}`)
    );


    //console.log(props);
    document.body.classList.add("about","scroll", "regular");

    var topControl = document.querySelector(".topControl");


    gsap.to(topControl, {
  scrollTrigger: ".triggerWhite", // start the animation when ".section" enters the viewport (once)
  start: "center top",
     scrub: 1,
  backgroundColor: 'white',
  duration:.8,
  delay:1.3
});





  },[]);
return (
  <Layout bg={'light'}>
{/*  <div className="imgHeader" style={{backgroundImage:`url('/static/layout/af-001.jpg')`}}></div> */}

{jsonData === null ? '' :

<YouTube
  ref={videoRef}
  videoId={jsonData[0].resourceId.videoId}
  onReady={(e)=>{
    const player = e.target;
    player.seekTo(0);
    player.playVideo();
    player.mute();

    setIsPlayer(player);
  }}
  opts={opts}
  className={'ytPlayer'}
/>
}

<div id="prodListHome" style={{height:4000+'px'}}>
<YouTubePlaylist
  apiKey="AIzaSyAvY1p1-ZaFmffdpCYCTWofGB2zukHTlCs"
  playlistId="PLHeUFog1D_MuNeT_OZ0fVtsChMIW0KL-p"
  uniqueName="af_films"
  limit={50}
  jsonData={jsonData}
  mainPlayer={isPlayer}
/>
</div>


  <div className="contentWrapper p-5 pt-2">

  <h1 className="triggerWhite text-center pb-2">ABOUT</h1>
  <p >
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras odio magna, placerat id magna quis, elementum hendrerit diam. Quisque rhoncus tristique eros, sit amet euismod augue ultricies a. Vivamus fermentum neque eu congue gravida. Sed eget purus interdum, congue lacus id, convallis mauris.
</p>
<p className="pb-5">Fusce ultrices lorem diam, non viverra massa varius in. Donec ultricies dolor magna, non euismod erat hendrerit vitae. Donec nec volutpat leo. Pellentesque vehicula leo vel nisl malesuada, id fermentum lacus porta. Praesent ut ipsum ut lectus maximus consequat sed et nulla. Nam finibus lacus at ultrices consequat. Aliquam diam nunc, venenatis eu metus non, tincidunt tincidunt ex. Duis vestibulum lorem eget pretium dignissim. Nunc sit amet malesuada dolor, nec vestibulum leo.
  </p>
    </div>

  </Layout>
)
});
export default AboutPage

export const Head = () => (
  <>
    <title>Frank Ariza - AF Films - About</title>
    <meta name="description" content="AF Films - About" />
  {/*  <link rel="prefetch" href="https://www.youtube.com/embed/Y-WUepaWR1Y?autoplay=1&muted=1&controls=0&enablejsapi=1&fs=0&start=1&end=10&loop=1&rel=0&modestbranding=1&showinfo=0&playlist=Y-WUepaWR1Y&hd=1&origin=http%3A%2F%2Flocalhost%3A8000&widgetid=1" as="document"/>
    <link rel="prefetch" href="https://www.youtube.com/embed/EXmSe_oeaCM?autoplay=1&muted=1&controls=0&enablejsapi=1&fs=0&start=1&end=10&loop=1&rel=0&modestbranding=1&showinfo=0&playlist=EXmSe_oeaCM&hd=1&origin=http%3A%2F%2Flocalhost%3A8000&widgetid=3" as="document"/>
    <link rel="prefetch" href="https://www.youtube.com/embed/LTyNt67olfM?autoplay=1&muted=1&controls=0&enablejsapi=1&fs=0&start=1&end=10&loop=1&rel=0&modestbranding=1&showinfo=0&playlist=LTyNt67olfM&hd=1&origin=http%3A%2F%2Flocalhost%3A8000&widgetid=5" as="document"/>
  */}
  <link rel="preload" href="https://discover.frankariza.com/app/v1/video-data" as="fetch"/>
  </>
)
