import React, { Component } from "react";
import YouTube from "react-youtube";
import "./styles.css"
// https://www.youtube.com/watch?v=-_pgcFQ0l64
// https://youtu.be/-_pgcFQ0l64
// https://www.youtube.com/watch?v=-_pgcFQ0l64&list=PLEsfXFp6DpzQbwYDx1zgcKJ4tzyWFaESK
class YtPlayer extends Component {

  constructor(props) {
      super(props);
      //console.log(props);

      this.state = {
        playerObj: null,
        _w : null
      }
this.videoOnReady = this.videoOnReady.bind(this);
    }
  videoOnReady(event) {
    // access to player in all event handlers via event.target
  //  console.log("video on ready");
    // event.target.playVideoAt(50); // 50 seconds
    const player = event.target;
     this.setState({playerObj: player});
    player.seekTo(10);
    player.pauseVideo();
    player.mute();
    //console.log(event.target);


  }
  videoOnPlay(event) {
    console.log('player start')
    // access to player in all event handlers via event.target
    // event.target.playVideoAt(50) // 50 seconds
    //const player = event.target;

    /// console.log(player.getCurrentTime())
  }
  videoOnPause(event) {
    console.log('player pause');
    // access to player in all event handlers via event.target
    // event.target.playVideoAt(50) // 50 seconds
    //const player = event.target;
    /// console.log(player.getCurrentTime())
  }
  videoStateChange(event) {
    //const player = event.target;
    //console.log(player.getCurrentTime());
    //console.log('changed');
  }
  componentDidMount() {
    
  }

  componentWillUnmount() {
    //const { playerObj } = this.state;
    //console.log(player.getCurrentTime());
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.state.playerObj !== null){
    if (
      this.props.isHover === this.props.elemId && this.props.playerAction === 'play'
    ) {
      console.log('YES', this.props.elemId);
      this.state.playerObj.playVideo();
    }else{
      this.state.playerObj.pauseVideo();
      this.state.playerObj.seekTo(0);
    }
    }
  }
  render() {
    const opts = {
      height: this.state._h,
      width: "auto",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        muted: 1,
        controls: 0,
        enablejsapi: 1,
        fs: 0,
        start:1,
        end: 10,
        loop: 1,
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        playlist: this.props.videoId,
        hd:1,
        origin: 'dev.frankariza.com'
      },
    };
    const { videoId, isHover,elemId, playerAction, classIsName } = this.props;
    //console.log(elemId, isHover, playerAction);

    return (
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={this.videoOnReady}
        onPlay={this.videoOnPlay}
        onStateChange={this.videoStateChange}
        className={classIsName}
      />
    );
  }
}

export default YtPlayer;
